import React from 'react';
import { node, oneOf, bool } from 'prop-types';
import classNames from 'classnames';

export default function Alert({ children, type, fixedPadding }) {
  const cx = classNames('alert', {
    [`alert--${type}`]: type,
    'alert--fixed-padding': fixedPadding,
  });
  return (
    <div className={cx} role="alert">
      <div className="alert__content">{children}</div>
    </div>
  );
}

Alert.propTypes = {
  children: node.isRequired,
  type: oneOf(['info', 'success', 'warning', 'danger']),
  fixedPadding: bool,
};

Alert.defaultProps = {
  type: 'info',
  fixedPadding: false,
};
