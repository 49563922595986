import React from 'react';
import { node, bool } from 'prop-types';
import classNames from 'classnames';

export default function ModalFlush({ children, vertical, horizontal }) {
  const cx = classNames('modal__flush', {
    'modal__flush--vertical': vertical,
    'modal__flush--horizontal': horizontal
  });
  return (
    <div className={cx}>{children}</div>
  );
}

ModalFlush.propTypes = {
  children: node.isRequired,
  vertical: bool,
  horizontal: bool,
};

ModalFlush.defaultProps = {
  vertical: false,
  horizontal: false,
};
