import React from 'react';
import Markdown from 'react-markdown';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import GlobalAlert from 'components/canvas/GlobalAlert';

const GLOBAL_BANNER_QUERY = gql`
  query siteSettings {
  	siteSetting {
      id
			GlobalBanner
    }
  }
`;

function GlobalBanner() {
  const { data } = useQuery(GLOBAL_BANNER_QUERY);
  const description = data?.siteSetting?.GlobalBanner;

  if (!description) {
    return null;
  }

  return (
    <GlobalAlert type="info" fixedPadding>
      <Markdown>
        { description }
      </Markdown>
    </GlobalAlert>
  )
}
export default GlobalBanner;

