import React from 'react';
import Link from 'next/link';
import Home from 'components/content/Home';
import Title from 'components/content/Title';
import Modal from 'components/canvas/Modal';
import Grid from 'components/composition/Grid';
import Button from 'components/controls/Button';

class HomeTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connectModalOpen: false
    };
  }

  componentDidMount() {
    if (typeof document === 'undefined') {
      return;
    }

    if (document.referrer === 'https://connect.cimaglobal.com/') {
      this.setState({ connectModalOpen: true });
    }
  }

  render() {
    const { connectModalOpen } = this.state;

    return (
      <>
        <Home />

        <Modal
          name="Connect migration"
          size="small"
          isOpen={connectModalOpen}
          fullHeightMobile={false}
          hideClose
        >
          <Title level={2} spacing theme="brand-primary">
            Looking for CIMA Connect?
          </Title>
          <p>
            You&apos;ve been redirected to the <strong>CGMA Study Hub</strong>.
          </p>
          <p>
            Here you can set up your personalised study plan, make study notes and tick off your progress as you go.
          </p>
          <Grid justify="flex-end">
            <Grid.Item>
              <Link href="/login" passHref>
                <Button>Get started</Button>
              </Link>
            </Grid.Item>
          </Grid>
        </Modal>
      </>
    );
  }
}

export default HomeTemplate;
