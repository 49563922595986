import React from 'react';
import { node, bool, string, oneOf } from 'prop-types';
import classNames from 'classnames';

import Item from './Item';

export default function Grid({
  children,
  wrap,
  direction,
  justify,
  align,
  inset,
  insetFluid,
  insetVerticalFluid,
  spacing,
}) {
  const cx = classNames('grid', {
    'grid--wrap': wrap,
    'grid--inset': inset,
    'grid--inset-fluid': insetFluid,
    'grid--inset-vertical-fluid': insetVerticalFluid,
    [`grid--${direction}`]: direction,
    [`grid--justify-${justify}`]: justify,
    [`grid--align-${align}`]: align,
    [`grid--spacing-${spacing}`]: spacing !== 'default',
  });

  return <div className={cx}>{children}</div>;
}

Grid.propTypes = {
  children: node,
  wrap: bool,
  inset: bool,
  insetFluid: bool,
  insetVerticalFluid: bool,
  direction: string,
  justify: string,
  align: string,
  spacing: oneOf(['none', 'default', 'xs', 'sm', 'lg', 'xl']),
};

Grid.defaultProps = {
  wrap: false,
  inset: false,
  insetFluid: false,
  insetVerticalFluid: false,
  children: '',
  direction: 'row',
  justify: 'flex-start',
  align: 'stretch',
  spacing: 'default',
};

Grid.Item = Item;
