import React from 'react';
import { node } from 'prop-types';

import Alert from 'components/canvas/Alert';

function GlobalAlert({ children, ...props }) {
  return (
    <div className="global-alert">
      <Alert {...props}>{children}</Alert>
    </div>
  );
}

GlobalAlert.propTypes = {
  children: node.isRequired,
};

export default GlobalAlert;
