import React from 'react';
import { node, oneOf, func, bool, string } from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import Icon from 'components/content/Icon';
import analytics from 'lib/analytics';
import Flush from './Flush';

export default class Modal extends React.Component {
  handleOpen = () => {
    const { name, onAfterOpen } = this.props;
    if (name !== '') {
      analytics.logModalView(name);
    }
    if (typeof onAfterOpen === 'function') {
      onAfterOpen();
    }
  }

  render() {
    const {
      name,
      children,
      size,
      onRequestClose,
      hideClose,
      fullHeightMobile,
      ...props
    } = this.props;

    const cx = classNames('modal', {
      [`modal--${size}`]: size,
      'modal--full-height': fullHeightMobile
    });

    return (
      <ReactModal
        className={cx}
        overlayClassName="modal__overlay"
        closeTimeoutMS={400}
        onRequestClose={onRequestClose}
        onAfterOpen={this.handleOpen}
        {...props}
      >
        {onRequestClose ? (
          <button className="modal__close" type="button" onClick={onRequestClose} hidden={hideClose}>
            <Icon type="times" />
          </button>
        ) : false}
        <div className="modal__inner">{children}</div>
      </ReactModal>
    );
  }
}

Modal.Flush = Flush;

Modal.propTypes = {
  name: string,
  children: node.isRequired,
  size: oneOf(['small', 'medium', 'large']),
  onRequestClose: func, // eslint-disable-line react/require-default-props
  hideClose: bool,
  fullHeightMobile: bool,
  onAfterOpen: func // eslint-disable-line react/require-default-props
};

Modal.defaultProps = {
  name: '',
  size: 'medium',
  hideClose: false,
  fullHeightMobile: true
};
