import React from 'react';
import { string, bool, node } from 'prop-types';
import classNames from 'classnames';
import Image from 'next/image';

import Icon from 'components/content/Icon';

const NoticeSidebarContent = ({ image, title, cta, color, banner, children }) => {
  const cx = classNames('notice-sidebar', {
    [`notice-sidebar--${color}`]: color,
    'notice-sidebar--banner': banner,
  });

  const size = banner ? {
    width: 1196,
    height: 512,
    sizes: '(max-width: 1024px) 100vw, 50vw'
  } : {
    width: 640,
    height: 480,
    sizes: '(max-width: 1200px) 25vw, 318px'
  }

  return (
    <div className={cx}>
      {image !== '' ? (
        <div className="notice-sidebar__image">
          <Image
            className="notice-sidebar__image-src"
            src={image}
            alt="Ad"
            {...size}
          />
        </div>
      ) : (
        false
      )}
      <div className="notice-sidebar__content">
        <header className="notice-sidebar__header">
          <h2 className="notice-sidebar__title">{title}</h2>
        </header>
        {children}
        <span className="notice-sidebar__cta">
          <span className="notice-sidebar__cta-text">{cta}</span>
          <Icon type="arrow-right" className="notice-sidebar__cta-icon" />
        </span>
      </div>
    </div>
  );
};

export default class NoticeSidebar extends React.Component {
  render() {
    const {
      href,
      image,
      title,
      cta,
      color,
      newWindow,
      banner,
      children,
    } = this.props;

    const newWindowProps = newWindow ? {
      target: '_blank',
      rel: 'noopener'
    } : {};

    if (href) {
      return (
        <a
          href={href}
          {...newWindowProps}
        >
          <NoticeSidebarContent
            banner={banner}
            image={image}
            title={title}
            cta={cta}
            color={color}
          >
            {children}
          </NoticeSidebarContent>
        </a>
      );
    }

    return (
      <NoticeSidebarContent
        image={image}
        title={title}
        cta={cta}
        color={color}
      >
        {children}
      </NoticeSidebarContent>
    );
  }
}

NoticeSidebar.propTypes = {
  href: string,
  title: string,
  cta: string.isRequired,
  image: string,
  color: string,
  banner: bool,
  newWindow: bool,
  children: node,
};

NoticeSidebar.defaultProps = {
  href: '',
  title: '',
  image: '',
  color: '',
  banner: false,
  newWindow: true,
  children: null,
};

NoticeSidebarContent.propTypes = {
  title: string.isRequired,
  cta: string.isRequired,
  color: string,
  image: string,
  banner: bool,
  children: node,
}

NoticeSidebarContent.defaultProps = {
  image: '',
  color: '',
  banner: false,
  children: null,
};
