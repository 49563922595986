import React from 'react';
import { node, bool, number, shape, string } from 'prop-types';
import classNames from 'classnames';

export default function GridItem({
  children,
  grow,
  shrink,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  max,
  desktop,
  flex,
  grid,
  order,
  align,
}) {
  const cx = classNames('grid__item', {
    'grid__item--grow': grow,
    'grid__item--shrink': shrink,
    [`grid__item--xs-${xs}`]: xs,
    [`grid__item--sm-${sm}`]: sm,
    [`grid__item--md-${md}`]: md,
    [`grid__item--lg-${lg}`]: lg,
    [`grid__item--xl-${xl}`]: xl,
    [`grid__item--xxl-${xxl}`]: xxl,
    [`grid__item--xxxl-${xxxl}`]: xxxl,
    [`grid__item--max-${max}`]: max,
    [`grid__item--desktop-${desktop}`]: desktop,
    'grid__item--flex': flex,
    'grid__item--grid': grid,
    [`grid__item--order-xs-${order.xs}`]: order.xs,
    [`grid__item--order-sm-${order.sm}`]: order.sm,
    [`grid__item--order-md-${order.md}`]: order.md,
    [`grid__item--order-lg-${order.lg}`]: order.lg,
    [`grid__item--order-xl-${order.xl}`]: order.xl,
    [`grid__item--order-xxl-${order.xxl}`]: order.xxl,
    [`grid__item--order-xxxl-${order.xxxl}`]: order.xxxl,
    [`grid__item--order-max-${order.max}`]: order.max,
    [`grid__item--order-desktop-${order.desktop}`]: order.desktop,
    [`grid__item--align-${align}`]: align,
  });

  return <div className={cx}>{children}</div>;
}

GridItem.propTypes = {
  children: node,
  grow: bool,
  shrink: bool,
  xs: number,
  sm: number,
  md: number,
  lg: number,
  xl: number,
  xxl: number,
  xxxl: number,
  max: number,
  desktop: number,
  flex: bool,
  order: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
    xxl: number,
    xxxl: number,
    max: number,
    desktop: number,
  }),
  align: string,
  grid: bool,
};

GridItem.defaultProps = {
  children: '',
  grow: false,
  shrink: false,
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
  xxl: 0,
  xxxl: 0,
  max: 0,
  desktop: 0,
  flex: false,
  order: {},
  grid: false,
  align: '',
};
