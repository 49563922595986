/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import Head from 'next/head';
import Image from 'next/image'

import Button from 'components/controls/Button';
import Grid from 'components/composition/Grid';
import Main from 'components/composition/Main';
import Title from 'components/content/Title';
import Lead from 'components/content/Lead';
import GlobalBanner from 'components/content/GlobalBanner';
import Spacing from 'components/composition/Spacing';
import Icon from 'components/content/Icon'
import NoticeSidebar from 'components/content/NoticeSidebar';
import { useUser } from 'lib/helpers/login';

import plannerScreenshot from '../../../public/images/pages/home/cima-planner-screen-shot.png';

export default function HomeTemplate() {
  const { isLoggedIn } = useUser();

  let action;

  if (isLoggedIn) {
    action = (
      <div className="home__actions">
        <Link href="/dashboard" passHref>
          <Button type="white">
            View your Dashboard
          </Button>
        </Link>
      </div>
    );
  } else {
    action = (
      <div className="home__actions">
        <Link href="/login" passHref>
          <Button type="white">Get started</Button>
        </Link>
        <Link href="/login" passHref>
          <Button type="outline-white">Login</Button>
        </Link>
      </div>
    );
  }

  return (
    <Main>
      <section className="home">
        <Head>
          <title>Home | CGMA Study Hub</title>
        </Head>
        <div className="home__hero">
          <div className="home__hero-inner">
            <div className="home__hero-content">
              <Grid wrap align="center">
                <Grid.Item xs={12} lg={7}>
                  <Spacing bottom="sm">
                    <p className="home__hero-content-context">The Chartered Institute of Management Accountants®</p>
                    <Title level={1} size="h1" responsive theme="white">Welcome to the CGMA Study Hub</Title>
                  </Spacing>
                  <Lead>
                    Maximise your chances of exam success with our exclusive resource library.
                  </Lead>
                  {action}
                </Grid.Item>
              </Grid>
            </div>
          </div>
        </div>
        <section className="home__section">
          <GlobalBanner />
          <div className="home__section-inner">
            <div className="home__section-inner__intro-content">
              <Grid wrap>
                <Grid.Item xs={12} lg={6}>
                  <p>
                    The CGMA® Study Hub provides a wide range of free, carefully curated content
                    to aid your study, revision and exam preparation. Get insights from the actual examiners,
                    markers and training providers, along with advice from top-performing candidates.
                  </p>
                  <Title level={2} size="h4" spacing><strong>Includes:</strong></Title>
                  <ul>
                    <li>An onboarding guide covering where to start</li>
                    <li>Tricky topic tutorials</li>
                    <li>Webcasts on specialised topics, such as time management and memory retrieval techniques</li>
                    <li>Tools to aid your study planning, including pro forma study timetables</li>
                    <li>Exam information </li>
                    <li>The latest wellbeing updates</li>
                    <li>How to access free personalised one-on-one coaching </li>
                    <li>A definitive guide for re-sit students</li>
                    <li>Previous Case Study exam questions and example answers</li>
                    <li>Pre-seen information</li>
                  </ul>
                  <p>The Study Support Team</p>
                </Grid.Item>
                <Grid.Item xs={12} lg={6}>
                  <Image
                    src={plannerScreenshot}
                    alt="Screen shot of CGMA Study Hub"
                    className="outset-image outset-image--right"
                    sizes="(max-width: 767px) 100vw, (max-width: 1200px) 50vw, 532px"
                  />
                </Grid.Item>
              </Grid>
            </div>
          </div>
        </section>

        <section className="home__section splash-box">
          <div className="splash-box__text-box splash-box__text-box--green splash-box__text-box--right">
            <Title level={2} size="h2" responsive spacing>Unsure what to read and when?</Title>
            <p>
              Our study guides provide all the answers, with calendared
              content to be viewed in a specific order. The material evolves from study support to exam
              support as your exam day approaches.
            </p>
            <Link
              href={isLoggedIn ? '/dashboard' : '/login'}
            >
              <a>
                {isLoggedIn ? 'View your Dashboard' : 'Log in'} <Icon type="arrow-right" />
              </a>
            </Link>
          </div>
          <img
            src="/images/pages/home/laptop.webp"
            alt="CGMA Study Hub displayed on a laptop screen"
            className="splash-box__image"
            width={932}
            height={621}
          />
        </section>
        <Spacing bottom="lg" top="lg">
          <NoticeSidebar
            image="/images/pages/home/smiling-lady.png"
            href="https://cimastudy.com?utm_source=cima&amp;utm_medium=web&amp;utm_content=home-banner&amp;utm_campaign=study-planner"
            title="Have you tried CIMA Study yet?"
            cta="Find out more on CIMAStudy.com"
            banner
          >
            <Spacing bottom="sm">
              <Lead>
                An in-depth resource designed by CIMA.
              </Lead>
            </Spacing>
            <ul>
              <li>
                Over 120 hours of online learning content per module.
              </li>
              <li>
                Tablet friendly content, you can study on the move.
              </li>
              <li>
                Online access for 12 months.
              </li>
            </ul>
          </NoticeSidebar>
        </Spacing>
      </section>
    </Main>
  );
}
